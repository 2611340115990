import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() currentPage = 1;
  @Input() totalPage = 1;
  @Output('page') pageEmitter?: EventEmitter<number>;
  ngOnInit(): void {
    this.pageEmitter = new EventEmitter<number>();

  }


}
