import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {GlobalUserSettingValues} from '../constants/setting-keys';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  transform(value: number | string | undefined): string {
    if (value == null) {
      value = 0;
    }
    return formatCurrency(
      +value,
      'en',
      getCurrencySymbol(GlobalUserSettingValues.CurrencyPrefix + ' ', 'narrow'),
      '',
      '2.2-2',
    );
  }

  transformWithNoCurrency(value: number | string | undefined): string {
    if (value == null) {
      value = 0;
    }
    return formatCurrency(
      +value,
      'en',
      '',
      '',
      '2.2-2',
    );
  }

}
