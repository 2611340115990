import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RoleNames } from '../../constants/role-names';
import { ReservationRequestClient } from '../../api/api';
import { SignalRService } from '../../services/signal-r.service';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

// Metadata
export class RouteInfo {
  path?: string;
  title?: string;
  type?: string;
  collapse?: string;
  isOpenDrawer?: boolean;
  isCollapse = true;
  icontype?: string;
  children?: ChildrenItems[];
  badge? = '';
  showBadge? = false;
}

export class ChildrenItems {
  path?: string;
  title?: string;
  ab?: string;
  type?: string;
}

// Menu Items

export const ADMINUPPERROUTES: RouteInfo[] = [
  // {path: '/dashboard/profile', title: 'Profile', type: 'link', icontype: 'fas fa fa-user'},
  {
    path: '/dashboard/change-password',
    title: 'Change Password',
    type: 'link',
    icontype: 'fas fa fa-key',
    isCollapse: true,
  },
  {
    path: '/logout',
    title: 'Logout',
    type: 'link',
    icontype: 'fas fa fa-sign-out-alt',
    isCollapse: true,
  },
];
// export const STAFFROUTES: RouteInfo[] = [
//   {path: '/dashboard/staff-home', title: 'Home', type: 'link', icontype: 'fas fa fa-home'},
//   {path: '/dashboard/notifications', title: 'Notifications', type: 'link', icontype: 'fas fa fa-bell', showBadge: true, badge: '10'},
//   {
//     path: '/dashboard/customers/', title: 'Customers', type: 'sub', icontype: 'fas fa fa-users', collapse: 'customers', children: [
//       {path: 'create', title: 'Create Customer', ab: 'CC', type: 'link'},
//       {path: 'view', title: 'View Customer', ab: 'VC', type: 'link'},
//     ]
//   },
//   {
//     path: '/dashboard/equipments/', title: 'Equipments', type: 'sub', icontype: 'fas fa fa-dolly', collapse: 'devices', children: [
//       {path: 'create', title: 'Create Equipment', ab: 'CE', type: 'link'},
//       {path: 'view', title: 'View Equipment', ab: 'VE', type: 'link'},
//     ]
//   },
//   {
//     path: '/dashboard/workers/', title: 'Worker', type: 'sub', icontype: 'fas fa fa-briefcase', collapse: 'workers', children: [
//       {path: 'create', title: 'Create Worker', ab: 'CW', type: 'link'},
//       {path: 'view', title: 'View Worker', ab: 'VW', type: 'link'},
//     ]
//   },
//   {
//     path: '/dashboard/jobs/', title: 'Jobs', type: 'sub', icontype: 'fas fa fa-clipboard-list', collapse: 'jobs', children: [
//       {path: 'create', title: 'Create Job', ab: 'CJ', type: 'link'},
//       {path: 'view', title: 'View Job', ab: 'VJ', type: 'link'},
//     ]
//   },
//   {path: '/dashboard/broadcasts/', title: 'Broadcasts', type: 'link', icontype: 'fas fa fa-bullhorn', collapse: 'broadcasts'},
//   {path: '/dashboard/chats/', title: 'Chats', type: 'link', icontype: 'fas fa fa-comment', collapse: 'chats'},
//   {
//     path: '/dashboard/worker-tracking-location/',
//     title: 'Tracking',
//     type: 'link',
//     icontype: 'fas fa fa-map-marker-alt',
//     collapse: 'broadcasts'
//   },
//   {
//     path: '/dashboard/form-builders/',
//     title: 'Form Builders',
//     type: 'sub',
//     icontype: 'fas fa fa-clipboard-check',
//     collapse: 'inquiry-forms',
//     children: [
//       {path: 'create', title: 'Create Form', ab: 'CF', type: 'link'},
//       {path: 'view', title: 'View Form', ab: 'VF', type: 'link'},
//     ]
//   },
//   // {
//   //   path: '/dashboard/tickets/', title: 'Tickets', type: 'sub', icontype: 'fas fa fa-ticket-alt', collapse: 'tickets', children: [
//   //     {path: 'resolved', title: 'Resolved', ab: 'R', type: 'link'},
//   //     {path: 'pending', title: 'Pending', ab: 'P', type: 'link'},
//   //   ]
//   // },
//   {path: '/dashboard/company-services', title: 'Company services', type: 'link', icontype: 'fas fa fa-network-wired'},
//   {
//     path: '/dashboard/settings/', title: 'Settings', type: 'sub', icontype: 'fas fa fa-cogs', collapse: 'settings', children: [
//       {path: 'company-logo', title: 'Company Logo', ab: 'CL', type: 'link'},
//     ]
//   },
// ];
//
// export const COMPANYROUTES: RouteInfo[] = [
//   {path: '/dashboard/company-home', title: 'Home', type: 'link', icontype: 'fas fa fa-home'},
//   {path: '/dashboard/notifications', title: 'Notifications', type: 'link', icontype: 'fas fa fa-bell', showBadge: true, badge: '10'},
// // {
//   //   path: '/dashboard/customers/', title: 'Customers', type: 'sub', icontype: 'fas fa fa-users', collapse: 'customers', children: [
//   //     {path: 'create', title: 'Create Customer', ab: 'CC', type: 'link'},
//   //     {path: 'view', title: 'View Customer', ab: 'VC', type: 'link'},
//   //   ]
//   // },
//   {
//     path: '/dashboard/staffs/', title: 'Staff', type: 'sub', icontype: 'fas fa fa-id-badge', collapse: 'staffs', children: [
//       {path: 'create', title: 'Create Staff', ab: 'CM', type: 'link'},
//       {path: 'view', title: 'View Staff', ab: 'VM', type: 'link'},
//     ]
//   },
//   {
//     path: '/dashboard/workers/', title: 'Worker', type: 'sub', icontype: 'fas fa fa-briefcase', collapse: 'workers', children: [
//       {path: 'create', title: 'Create Worker', ab: 'CW', type: 'link'},
//       {path: 'view', title: 'View Worker', ab: 'VW', type: 'link'},
//     ]
//   },
//   // {
//   //   path: '/dashboard/inquiry-forms/',
//   title: 'Form Builders', type: 'sub', icontype: 'fas fa fa-clipboard-check', collapse: 'inquiry-forms', children: [
//   //     {path: 'create', title: 'Create Form', ab: 'CF', type: 'link'},
//   //     {path: 'view', title: 'View Form', ab: 'VF', type: 'link'},
//   //   ]
//   // },
//   {
//     path: '/dashboard/tickets/', title: 'Tickets', type: 'sub', icontype: 'fas fa fa-ticket-alt', collapse: 'tickets', children: [
//       {path: 'resolved', title: 'Resolved', ab: 'R', type: 'link'},
//       {path: 'pending', title: 'Pending', ab: 'P', type: 'link'},
//     ]
//   },
//   {path: '/dashboard/company-services', title: 'Company services', type: 'link', icontype: 'fas fa fa-network-wired'},
//   {path: '/dashboard/company-settings', title: 'Settings', type: 'link', icontype: 'fas fa fa-cog'},
// ];

export const ADMINROUTES: RouteInfo[] = [
  // { path: "/dashboard/home", title: "Home", type: "link", icontype: "fas fa fa-home", isCollapse: true },
  {
    path: '/dashboard/profile',
    title: 'Open Settings',
    isOpenDrawer: true,
    type: 'link',
    icontype: 'fas fa fa-house',
    isCollapse: true,
  },
  // {
  //   path: "/dashboard/reservations/create",
  //   title: "Create Reservation",
  //   type: "link",
  //   icontype: "fas fa fa-book-open",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/walk-in",
  //   title: "Create Walk In",
  //   type: "link",
  //   icontype: "fa-solid fa-person-walking-luggage",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/settings",
  //   title: "Settings",
  //   type: "link",
  //   icontype: "fas fa fa-cog",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/customers",
  //   title: "Customers",
  //   type: "link",
  //   icontype: "fas fa fa-user-friends",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/users",
  //   title: "Users",
  //   type: "link",
  //   icontype: "fas fa fa-users-line",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/requests",
  //   title: "Requests",
  //   type: "link",
  //   icontype: "fas fa fa-code-pull-request",
  //   isCollapse: true,
  // },
  // {
  //   path: "/dashboard/reservations",
  //   title: "Reservations",
  //   type: "link",
  //   icontype: "fas fa fa-book",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/reservation-calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "fas fa fa-calendar-days",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/menus",
  //   title: "Menu",
  //   type: "link",
  //   icontype: "fas fa fa-utensils",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/notifications",
  //   title: "Notifications",
  //   type: "link",
  //   icontype: "fas fa fa-bell",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/templates",
  //   title: "Templates",
  //   type: "link",
  //   icontype: "fas fa fa-align-center",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/dining-areas",
  //   title: "Dining Area",
  //   type: "link",
  //   icontype: "fas fa fa-layer-group",
  //   isCollapse: true
  // },

  {
    path: '/dashboard/restaurant',
    title: 'Restaurant',
    type: 'link',
    icontype: 'fas fa fa-layer-group',
    isCollapse: true,
  },

  // {
  //   path: "/dashboard/dining-services",
  //   title: "Dining Services",
  //   type: "link",
  //   icontype: "fas fa fa-bell-concierge",
  //   isCollapse: true
  // },
  // {
  //   path: "/dashboard/campaigns",
  //   title: "Email Campaigns",
  //   type: "link",
  //   icontype: "fa-solid fa-bullhorn",
  //   isCollapse: true
  // },
  // {
  //   path: '/dashboard/companies/', title: 'Companies', type: 'sub', icontype: 'fas fa fa-building', collapse: 'components', children: [
  //     {path: 'create', title: 'Create Company', ab: 'CC', type: 'link'},
  //     {path: 'view', title: 'View Companies', ab: 'VC', type: 'link'},
  //   ]
  // },
  // {
  //   path: '/dashboard/tickets/', title: 'Tickets', type: 'sub', icontype: 'fas fa fa-ticket-alt', collapse: 'tickets', children: [
  //     {path: 'resolved', title: 'Resolved', ab: 'R', type: 'link'},
  //     {path: 'pending', title: 'Pending', ab: 'P', type: 'link'},
  //   ]
  // },
  // {path: '/dashboard/business-types', title: 'Business Types', type: 'link', icontype: 'fas fa fa-network-wired'},
  // {
  //   path: '/dashboard/settings/', title: 'Settings', type: 'sub', icontype: 'fas fa fa-cogs', collapse: 'settings', children: [
  //     {path: 'company-logo', title: 'Company Logo', ab: 'CL', type: 'link'},
  //   ]
  // },
];

export const OWNERROUTES: RouteInfo[] = [
  {
    path: '/dashboard/profile',
    title: 'Open Settings',
    isOpenDrawer: true,
    type: 'link',
    icontype: 'fas fa fa-house',
    isCollapse: true,
  },
  {
    path: '/dashboard/reservations/create',
    title: 'Create Reservation',
    type: 'link',
    icontype: 'fas fa fa-book-open',
    isCollapse: true,
  },
  {
    path: '/dashboard/walk-in',
    title: 'Create Walk In',
    type: 'link',
    icontype: 'fa-solid fa-person-walking-luggage',
    isCollapse: true,
  },
  {
    path: '/dashboard/settings',
    title: 'Settings',
    type: 'link',
    icontype: 'fas fa fa-cog',
    isCollapse: true,
  },
  {
    path: '/dashboard/customers',
    title: 'Customers',
    type: 'link',
    icontype: 'fas fa fa-user-friends',
    isCollapse: true,
  },
  {
    path: '/dashboard/floor-plans',
    title: 'Floor Plans',
    type: 'link',
    icontype: 'fas fa fa-solar-panel',
    isCollapse: true,
  },
  {
    path: '/dashboard/menus',
    title: 'Menu',
    type: 'link',
    icontype: 'fas fa fa-utensils',
    isCollapse: true,
  },
  {
    path: '/dashboard/requests',
    title: 'Requests',
    type: 'link',
    icontype: 'fas fa fa-code-pull-request',
    isCollapse: true,
  },
  {
    path: '/dashboard/reservations',
    title: 'Reservations',
    type: 'link',
    icontype: 'fas fa fa-book',
    isCollapse: true,
  },
  {
    path: '/dashboard/reservation-calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'fas fa fa-calendar-days',
    isCollapse: true,
  },
  {
    path: '/dashboard/notifications',
    title: 'Notifications',
    type: 'link',
    icontype: 'fas fa fa-bell',
    isCollapse: true,
  },
  {
    path: '/dashboard/templates',
    title: 'Templates',
    type: 'link',
    icontype: 'fas fa fa-align-center',
    isCollapse: true,
  },
  {
    path: '/dashboard/dining-areas',
    title: 'Dining Area',
    type: 'link',
    icontype: 'fas fa fa-layer-group',
    isCollapse: true,
  },

  {
    path: '/dashboard/dining-services',
    title: 'Dining Services',
    type: 'link',
    icontype: 'fas fa fa-bell-concierge',
    isCollapse: true,
  },
  {
    path: '/dashboard/campaigns',
    title: 'Email Campaigns',
    type: 'link',
    icontype: 'fa-solid fa-bullhorn',
    isCollapse: true,
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  showDrawer = false;
  subscriptions: Subscription[] = [];
  public static Routes?: RouteInfo[];
  reservationCount = 0;
  currentRoutes?: RouteInfo[];
  upperMenuRoutes?: RouteInfo[];
  isCollapsed = true;

  constructor(
    private navigationService: NavigationService,
    private signalRService: SignalRService,
    private reservationClient: ReservationRequestClient,
    private authService: AuthService
  ) {}

  isNotMobileMenu(): boolean {
    return window.outerWidth <= 991;
  }

  initHandlers(): void {
    this.subscriptions.push(
      this.signalRService.getNewReservationRequestEvent().subscribe((x) => {
        this.getRequestCount();
      })
    );
  }

  ngOnInit(): void {
    this.initHandlers();
    if (this.authService.isRole(RoleNames.Administrator)) {
      this.currentRoutes = ADMINROUTES.filter(() => true);
      this.upperMenuRoutes = ADMINUPPERROUTES.filter(() => true);
    }
    if (this.authService.isRole(RoleNames.RestaurantOwner)) {
      this.currentRoutes = OWNERROUTES.filter(() => true);
      this.upperMenuRoutes = ADMINUPPERROUTES.filter(() => true);
    }
    if (this.authService.isRole(RoleNames.Employee)) {
      this.currentRoutes = OWNERROUTES.filter(
        (p) => p.title !== 'Employees' && p.title !== 'Settings'
      );
      this.upperMenuRoutes = ADMINUPPERROUTES.filter(() => true);
    }
    SidebarComponent.Routes = this.currentRoutes?.filter(() => true);
  }

  getRequestCount() {
    if (this.authService.isLoggedIn) {
      this.reservationClient
        .reservationRequest_GetPendingReservationRequest()
        .subscribe((p) => {
          this.reservationCount = p.count ?? 0;
          const item = this.currentRoutes?.find((p) => p.title == 'Requests');
          if (item) {
            item.badge = this.reservationCount.toString();
          }
        });
    }
  }

  getProfileImage(): string {
    return this.authService.currentUser.image ?? '/assets/img/default-user.png';
  }

  getProfileName(): string {
    return this.authService.currentUser.fullName as string;
  }

  ngAfterViewInit(): void {
    this.getRequestCount();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }

  toggleDrawer() {
    this.navigationService.toggleSideBar();
  }
}
