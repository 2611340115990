// ====================================================


// ====================================================

import {Component, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounce, debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent implements OnInit {

  @Input()
  showSuggestions = false;

  @Input()
  isLoading = false;

  @Input()
  suggestions: string[] = [];

  @Input()
  isFullWidth = false;

  @Input() noMargin = false;
  @Input()
  placeholder = 'Search...';

  @Input()
  set searchValue(value: string) {
    this.searchControl.setValue(value);
  }

  @Input()
  set resetValue(value: boolean) {
    if (value) {
      this.searchControl.reset();
    }
  }

  @Output()
  searchChange = new EventEmitter<string>();

  @Output()
  valueChange = new EventEmitter<string>();


  // @ViewChild('searchInput', {static: false})
  // searchInput: ElementRef;
  searchControl: FormControl = new FormControl();


  constructor(private ngCheck: ChangeDetectorRef) {
  }

  initForm() {
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.onSearchChange();
    });
  }
  onSearchChange() {
    setTimeout(() => this.searchChange.emit(this.searchControl.value));
  }

  clear() {
    this.searchControl.reset();
  }

  clearValue() {
    this.searchControl.reset();
  }

  setValue(value: string) {
    this.searchControl.setValue(value);
  }

  ngOnInit(): void {
    this.initForm();
  }
}
