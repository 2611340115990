import {Pipe, PipeTransform} from "@angular/core";
import {TemplateType} from "../enums/inventory-reason-type";

@Pipe({
  name: 'templateType'
})
export class TemplateTypePipe implements PipeTransform {

  transform(value: number | undefined): string {
    if (value != undefined) {
      switch (value as TemplateType) {
        case TemplateType.Pending:
          return 'Pending';
        case TemplateType.Arrived:
          return 'Arrived';
        case TemplateType.Cancelled:
          return 'Cancelled';
        case TemplateType.Left:
          return 'Left';
        case TemplateType.Confirmed:
          return 'Confirmed';
        case TemplateType.NoShow:
          return 'No Show';
        case TemplateType.Payed:
          return 'Payed';
      }
    }
    return '';
  }

}

