import { Injectable } from '@angular/core';
import {Dbkey} from './db-key';
import {Utilities} from './utilities';
import {LocalStoreManager} from './local-store-manager.service';
import {environment} from '../../environments/environment';

interface UserConfiguration {
  language?: string;
  homeUrl?: string;
  theme?: string;
  showDashboardStatistics?: boolean;
  showDashboardNotifications?: boolean;
  showDashboardTodo?: boolean;
  showDashboardBanner?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {



  public static readonly defaultLanguage: string = 'en';
  public static readonly defaultHomeUrl: string = '/';
  public static readonly defaultTheme: string = 'Default';
  public static readonly defaultShowDashboardStatistics: boolean = true;
  public static readonly defaultShowDashboardNotifications: boolean = true;
  public static readonly defaultShowDashboardTodo: boolean = false;
  public static readonly defaultShowDashboardBanner: boolean = true;
  public static readonly appVersion: string = '1.0';
  public static readonly isUseFakeApi = false;
  public baseUrl: string = Utilities.baseUrl().replace(/\/$/, '');
  // public baseUrl = 'https://localhost:5001';
  public loginUrl = '/login';
  public fallbackBaseUrl = 'asdasda';
  private _language?: string;
  private _homeUrl?: string;
  private _theme?: string;
  private _showDashboardStatistics?: boolean;
  private _showDashboardNotifications?: boolean;
  private _showDashboardTodo?: boolean;
  private _showDashboardBanner?: boolean;


  constructor(private localStorage: LocalStoreManager) {
    this.loadLocalChanges();
    if (environment.baseUrl) {
      this.baseUrl = environment.baseUrl;
    }
  }



  private loadLocalChanges() {

    if (this.localStorage.exists(Dbkey.LANGUAGE)) {
      this._language = this.localStorage.getDataObject<string>(Dbkey.LANGUAGE);
    }


    if (this.localStorage.exists(Dbkey.HOME_URL)) {
      this._homeUrl = this.localStorage.getDataObject<string>(Dbkey.HOME_URL);
    }

    if (this.localStorage.exists(Dbkey.THEME)) {
      this._theme = this.localStorage.getDataObject<string>(Dbkey.THEME);
    }

    if (this.localStorage.exists(Dbkey.SHOW_DASHBOARD_STATISTICS)) {
      this._showDashboardStatistics = this.localStorage.getDataObject<boolean>(Dbkey.SHOW_DASHBOARD_STATISTICS);
    }

    if (this.localStorage.exists(Dbkey.SHOW_DASHBOARD_NOTIFICATIONS)) {
      this._showDashboardNotifications = this.localStorage.getDataObject<boolean>(Dbkey.SHOW_DASHBOARD_NOTIFICATIONS);
    }

    if (this.localStorage.exists(Dbkey.SHOW_DASHBOARD_TODO)) {
      this._showDashboardTodo = this.localStorage.getDataObject<boolean>(Dbkey.SHOW_DASHBOARD_TODO);
    }

    if (this.localStorage.exists(Dbkey.SHOW_DASHBOARD_BANNER)) {
      this._showDashboardBanner = this.localStorage.getDataObject<boolean>(Dbkey.SHOW_DASHBOARD_BANNER);
    }
  }


  private saveToLocalStore(data: any, key: string) {
    setTimeout(() => this.localStorage.savePermanentData(data, key));
  }


  public import(jsonValue: string) {

    this.clearLocalChanges();

    if (!jsonValue) {
      return;
    }

    const importValue: UserConfiguration = Utilities.JSonTryParse(jsonValue);

    if (importValue.language != null) {
      this.language = importValue.language;
    }

    if (importValue.homeUrl != null) {
      this.homeUrl = importValue.homeUrl;
    }

    if (importValue.theme != null) {
      this.theme = importValue.theme;
    }

    if (importValue.showDashboardStatistics != null) {
      this.showDashboardStatistics = importValue.showDashboardStatistics;
    }

    if (importValue.showDashboardNotifications != null) {
      this.showDashboardNotifications = importValue.showDashboardNotifications;
    }

    if (importValue.showDashboardTodo != null) {
      this.showDashboardTodo = importValue.showDashboardTodo;
    }

    if (importValue.showDashboardBanner != null) {
      this.showDashboardBanner = importValue.showDashboardBanner;
    }
  }


  public export(changesOnly = true): string {

    const exportValue: UserConfiguration = {
        language: changesOnly ? this._language : this.language,
        homeUrl: changesOnly ? this._homeUrl : this.homeUrl,
        theme: changesOnly ? this._theme : this.theme,
        showDashboardStatistics: changesOnly ? this._showDashboardStatistics : this.showDashboardStatistics,
        showDashboardNotifications: changesOnly ? this._showDashboardNotifications : this.showDashboardNotifications,
        showDashboardTodo: changesOnly ? this._showDashboardTodo : this.showDashboardTodo,
        showDashboardBanner: changesOnly ? this._showDashboardBanner : this.showDashboardBanner
      };

    return JSON.stringify(exportValue);
  }


  public clearLocalChanges() {
    this._language;
    this._homeUrl;
    this._theme;
    this._showDashboardStatistics;
    this._showDashboardNotifications;
    this._showDashboardTodo;
    this._showDashboardBanner;

    this.localStorage.deleteData(Dbkey.LANGUAGE);
    this.localStorage.deleteData(Dbkey.HOME_URL);
    this.localStorage.deleteData(Dbkey.THEME);
    this.localStorage.deleteData(Dbkey.SHOW_DASHBOARD_STATISTICS);
    this.localStorage.deleteData(Dbkey.SHOW_DASHBOARD_NOTIFICATIONS);
    this.localStorage.deleteData(Dbkey.SHOW_DASHBOARD_TODO);
    this.localStorage.deleteData(Dbkey.SHOW_DASHBOARD_BANNER);

  }







  set language(value: string) {
    this._language = value;
    this.saveToLocalStore(value, Dbkey.LANGUAGE);
  }
  get language() {
    if (this._language != null) {
      return this._language;
    }

    return ConfigurationService.defaultLanguage;
  }


  set homeUrl(value: string) {
    this._homeUrl = value;
    this.saveToLocalStore(value, Dbkey.HOME_URL);
  }
  get homeUrl() {
    if (this._homeUrl != null) {
      return this._homeUrl;
    }

    return ConfigurationService.defaultHomeUrl;
  }


  set theme(value: string) {
    this._theme = value;
    this.saveToLocalStore(value, Dbkey.THEME);
  }
  get theme() {
    if (this._theme != null) {
      return this._theme;
    }

    return ConfigurationService.defaultTheme;
  }


  set showDashboardStatistics(value: boolean) {
    this._showDashboardStatistics = value;
    this.saveToLocalStore(value, Dbkey.SHOW_DASHBOARD_STATISTICS);
  }
  get showDashboardStatistics() {
    if (this._showDashboardStatistics != null) {
      return this._showDashboardStatistics;
    }

    return ConfigurationService.defaultShowDashboardStatistics;
  }


  set showDashboardNotifications(value: boolean) {
    this._showDashboardNotifications = value;
    this.saveToLocalStore(value, Dbkey.SHOW_DASHBOARD_NOTIFICATIONS);
  }
  get showDashboardNotifications() {
    if (this._showDashboardNotifications != null) {
      return this._showDashboardNotifications;
    }

    return ConfigurationService.defaultShowDashboardNotifications;
  }


  set showDashboardTodo(value: boolean) {
    this._showDashboardTodo = value;
    this.saveToLocalStore(value, Dbkey.SHOW_DASHBOARD_TODO);
  }
  get showDashboardTodo() {
    if (this._showDashboardTodo != null) {
      return this._showDashboardTodo;
    }

    return ConfigurationService.defaultShowDashboardTodo;
  }


  set showDashboardBanner(value: boolean) {
    this._showDashboardBanner = value;
    this.saveToLocalStore(value, Dbkey.SHOW_DASHBOARD_BANNER);
  }
  get showDashboardBanner() {
    if (this._showDashboardBanner != null) {
      return this._showDashboardBanner;
    }

    return ConfigurationService.defaultShowDashboardBanner;
  }
}
