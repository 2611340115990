import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BoolToLabelPipe } from './bool-to-label.pipe';
import { AppCurrencyPipe } from './app-currency.pipe';
import { AppDatePipe } from './app-date.pipe';
import { TemplateDeviceTypePipe } from './template-device-type.pipe';
import { TemplateTypePipe } from './template-type.pipe';
import { TrimPipe } from './trim.pipe';
import { NotificationTypePipe } from './notification-type.pipe';
import { TemplateTypeHtmlPipe } from './template-type-html.pipe';
import { SafePipe } from './safe.pipe';
import { ReservationRequestStatusHtmlPipe } from './reservation-request-status-html.pipe';
import { AppTimePipe } from './app-time.pipe';

@NgModule({
  declarations: [
    ReservationRequestStatusHtmlPipe,
    SafePipe,
    BoolToLabelPipe,
    AppCurrencyPipe,
    AppDatePipe,
    TemplateDeviceTypePipe,
    TemplateTypePipe,
    TrimPipe,
    NotificationTypePipe,
    TemplateTypeHtmlPipe,
    AppTimePipe,
  ],
  exports: [
    AppTimePipe,
    ReservationRequestStatusHtmlPipe,
    SafePipe,
    BoolToLabelPipe,
    AppCurrencyPipe,
    AppDatePipe,
    TemplateDeviceTypePipe,
    TemplateTypePipe,
    TrimPipe,
    NotificationTypePipe,
    TemplateTypeHtmlPipe,
  ],
  imports: [CommonModule],
  providers: [DatePipe],
})
export class PipesModule {}
