import {Pipe, PipeTransform} from '@angular/core';
import {TemplateDeviceType} from "../enums/inventory-reason-type";

@Pipe({
  name: 'templateDeviceType'
})
export class TemplateDeviceTypePipe implements PipeTransform {

  transform(value: number | undefined): string {
    if (value != undefined) {
      switch (value as TemplateDeviceType) {
        case TemplateDeviceType.Sms:
          return 'Sms';
        case TemplateDeviceType.Email:
          return 'Email';
      }
    }
    return '';
  }

}


