export class UserSettingKeys {
  public static readonly MinPeople = "MinPeople";
  public static readonly All = [
    UserSettingKeys.MinPeople,
  ];
}

export class GlobalUserSettingValues {
  public static CurrencyPrefix = '';
  public static DateFormat = 'dd/MM/YYYY HH:mm:ss';
}
