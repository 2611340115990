import {Pipe, PipeTransform} from '@angular/core';
import {TemplateType} from "../enums/inventory-reason-type";

@Pipe({
  name: 'templateTypeHtml'
})
export class TemplateTypeHtmlPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value != undefined) {
      switch (value as TemplateType) {
        case TemplateType.Pending:
          return '<span class="badge badge-azure">Pending</span>';
        case TemplateType.Arrived:
          return '<span class="badge badge-secondary">Arrived</span>';
        case TemplateType.Cancelled:
          return '<span class="badge badge-danger">Cancelled</span>';
        case TemplateType.Left:
          return '<span class="badge badge-info">Left</span>';
        case TemplateType.Confirmed:
          return '<span class="badge badge-success">Confirmed</span>';
        case TemplateType.NoShow:
          return '<span class="badge badge-warning">No Show</span>';
      }
    }
    return '';
  }
}

