import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {SearchBoxComponent} from './search-box/search-box.component';
import {FormErrorComponent} from './form-error/form-error.component';
import {NgModule} from '@angular/core';
import {ImageComponent} from './image/image.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BackButtonComponent} from './back-button/back-button.component';
import {LoaderComponent} from './loader/loader.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgbCollapseModule, NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import {NotificationViewModule} from "./notification-view/notification-view.module";

@NgModule({
  declarations: [SidebarComponent, SearchBoxComponent,
    PaginatorComponent, NavbarComponent,
    FormErrorComponent, ImageComponent, BackButtonComponent, LoaderComponent],
  exports: [SidebarComponent, SearchBoxComponent,
    NavbarComponent, FormErrorComponent,
    ImageComponent, BackButtonComponent, LoaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgbCollapseModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    NgbDropdownModule,
    NotificationViewModule,
  ]
})
export class SharedModule {
}
