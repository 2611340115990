import { Injectable } from '@angular/core';
import {EndpointFactoryService} from '../../../services/endpoint-factory.service';
import {ChangePassword} from '../../../models/change-password';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginEndpointService extends EndpointFactoryService {
  private readonly _changePassword: string = '/api/v1/user/changePassword';
  get changePasswordUrl() { return this.configurations.baseUrl + this._changePassword; }


  getChangePasswordEndpoint(changePassword: ChangePassword): Observable<any> {
    const body = {
      currentPassword: changePassword.currentPassword,
      confirmPassword: changePassword.confirmPassword,
      newPassword: changePassword.newPassword,
    };
    return this.http.post(this.changePasswordUrl, body, { headers: this.getRequestHeaders().headers });
  }
}
