import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationViewComponent } from './components/notification-view/notification-view.component';
import {PipesModule} from "../../pipes/pipes.module";



@NgModule({
  declarations: [
    NotificationViewComponent
  ],
  exports: [
    NotificationViewComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class NotificationViewModule { }
