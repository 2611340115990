import {Component, Input, OnInit} from '@angular/core';
import {NotificationDto} from "../../../../api/api";
import {NavigationService} from "../../../../services/navigation.service";
import {NotificationType} from "../../../../enums/inventory-reason-type";

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.css']
})
export class NotificationViewComponent implements OnInit {

  type = 0;
  dto: any;
  @Input() isAuto = false;
  get notification(): NotificationDto {
    return this._notification;
  }

  @Input() set notification(value: NotificationDto) {
    this._notification = value;
    if (this.notification.data) {
      this.dto = JSON.parse(value.data ?? '');
    }
  }

  private _notification!: NotificationDto;
  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  navigateToPage() {
    switch (this._notification.type as NotificationType) {
      case NotificationType.ReservationCreated:
        this.navigationService.navigateByUrl('dashboard/reservations/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationCancelled:
        this.navigationService.navigateByUrl('dashboard/reservations/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationConfirmed:
        this.navigationService.navigateByUrl('dashboard/reservations/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationPayed:
        this.navigationService.navigateByUrl('dashboard/reservations/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationRequestCreated:
        this.navigationService.navigateByUrl('dashboard/reservation-request/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationRequestAccepted:
        this.navigationService.navigateByUrl('dashboard/reservation-request/detail/' + this.dto.Id);
        break;
      case NotificationType.ReservationRequestRejected:
        this.navigationService.navigateByUrl('dashboard/reservation-request/detail/' + this.dto.Id);
        break;

    }
  }
}
