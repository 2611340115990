<div class="modal-header padding-10">
  <span class="modal-title font-weight-bold">
   Image
  </span>
</div>
<div class="modal-body padding-10" style="max-height: 400px; overflow-y: auto; overflow-x: hidden">
  <div class="row">
    <div class="col-sm text-center">
      <img [src]="host + image" class="img-fluid" alt="Image">
    </div>
  </div>
</div>
<div class="modal-footer padding-10">
  <button class="btn btn-neutral btn-round" (click)="close()">Close</button>
</div>
