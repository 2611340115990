import { DropDownItem } from '../models/key-value';
import { ReservationDto, ReservationStatus } from '../api/api';
import { TimeSpan } from '../models/time-span';
import * as moment from 'moment/moment';

export class RoleNames {
  public static readonly Administrator = 'Administrator';
  public static readonly RestaurantOwner = 'RestaurantOwner';
  public static readonly Employee = 'Employee';
}
export class AppHelper {
  public static IsLate(row: ReservationDto) {
    var status = row.status as ReservationStatus;
    if (status != ReservationStatus.Confirmed) {
      return false;
    }
    var date = moment(row.date, 'YYYY-MM-DD');
    var s = TimeSpan.fromString(row.time ?? '');
    date.add('second', s.totalSeconds);
    date.add('second', 1800);
    return date.isBefore(moment());
  }
}
export interface ShapeDto {
  type: string;
  text: string;
  lowerText: string;
  meta: any;
  isSelected: boolean;
}
export class ReservationConstants {
  public static readonly Zooms: DropDownItem[] = [
    {
      id: '50',
      value: '50%',
    },
    {
      id: '75',
      value: '75%',
    },
    {
      id: '100',
      value: '100%',
    },
    {
      id: '150',
      value: '150%',
    },
    {
      id: '200',
      value: '200%',
    },
    {
      id: '250',
      value: '250%',
    },
  ];
  public static readonly Occasions: DropDownItem[] = [
    { id: 'Anniversary', value: 'Anniversary' },
    { id: 'Honeymoon', value: 'Honeymoon' },
    { id: 'Birthday', value: 'Birthday' },
    { id: 'Business meeting', value: 'Business meeting' },
    { id: 'Bridal shower', value: 'Bridal shower' },
    { id: 'Bachelorette', value: 'Bachelorette' },
    { id: 'Engagement', value: 'Engagement' },
    { id: 'Graduation', value: 'Graduation' },
    { id: 'Bachelor', value: 'Bachelor' },
    { id: 'Baby shower', value: 'Baby shower' },
    { id: 'Corporate event', value: 'Corporate event' },
    { id: 'Post-event', value: 'Post-event' },
    { id: 'Pre-event', value: 'Pre-event' },
    { id: 'Wedding', value: 'Wedding' },
    { id: 'Post-theater', value: 'Post-theater' },
    { id: 'Special event', value: 'Special event' },
    { id: 'Male birthday', value: 'Male birthday' },
    { id: 'Job promotion', value: 'Job promotion' },
    { id: 'Pre-theater', value: 'Pre-theater' },
    { id: 'Catch up with friends', value: 'Catch up with friends' },
    { id: 'Congratulations', value: 'Congratulations' },
    { id: 'Female birthday', value: 'Female birthday' },
    { id: 'Business celebration', value: 'Business celebration' },
    { id: 'Celebration', value: 'Celebration' },
    { id: 'Holiday', value: 'Holiday' },
  ];
}
export class ServiceConstants {
  public static readonly Duration: DropDownItem[] = [
    { id: '30', value: '30 minutes' },
    { id: '45', value: '45 minutes' },
    { id: '60', value: '1 hour' },
    { id: '90', value: '1 hour 30 minutes' },
    { id: '120', value: '2 hour' },
    { id: '150', value: '2 hour 30 minutes' },
  ];
}
export class TableConstants {
  public static readonly Areas: DropDownItem[] = [
    { id: 'Bar', value: 'Bar' },
    { id: 'Bistro', value: 'Bistro' },
    { id: "Chef's Table", value: "Chef's Table" },
    { id: "Children's Area", value: "Children's Area" },
    { id: 'Downstairs', value: 'Downstairs' },
    { id: 'Garden', value: 'Garden' },
    { id: 'Inside', value: 'Inside' },
    { id: 'Lounge', value: 'Lounge' },
    { id: 'Outside', value: 'Outside' },
    { id: 'Patio', value: 'Patio' },
    { id: 'Private Dining Room', value: 'Private Dining Room' },
    { id: 'Rooftop', value: 'Rooftop' },
    { id: 'Seaside', value: 'Seaside' },
    { id: 'Terrace', value: 'Terrace' },
    { id: 'Upstairs', value: 'Upstairs' },
    { id: 'Winter Garden', value: 'Winter Garden' },
  ];
  public static readonly Standard: DropDownItem[] = [
    { id: '1', value: 'Standard' },
    { id: '2', value: 'Tall' },
    { id: '3', value: 'Low' },
    { id: '4', value: 'Shared' },
    { id: '5', value: 'Counter' },
  ];

  public static readonly Feature: DropDownItem[] = [
    { id: '1', value: 'Bay View' },
    { id: '2', value: 'City View' },
    { id: '3', value: 'Courtyard View' },
  ];
}
export class CustomerConstants {
  public static readonly Titles: DropDownItem[] = [
    {
      id: 'Mr',
      value: 'Mr',
    },
    {
      id: 'Mrs',
      value: 'Mrs',
    },
    {
      id: 'Mr and Mrs',
      value: 'Mr and Mrs',
    },
    {
      id: 'Dr',
      value: 'Dr',
    },
    {
      id: 'Professor',
      value: 'Professor',
    },
  ];
  public static readonly CountryExt: DropDownItem[] = [
    { id: '+93', value: '+93 - Afghanistan', extra: 'AF' },
    { id: '+355', value: '+355 - Albania', extra: 'AL' },
    { id: '+213', value: '+213 - Algeria', extra: 'DZ' },
    { id: '+1-684', value: '+1-684 - American Samoa', extra: 'AS' },
    { id: '+244', value: '+244 - Angola', extra: 'AO' },
    { id: '+1-264', value: '+1-264 - Anguilla', extra: 'AI' },
    { id: '+672', value: '+672 - Norfolk Island', extra: 'NF' },
    { id: '+1-268', value: '+1-268 - Antigua and Barbuda', extra: 'AG' },
    { id: '+54', value: '+54 - Argentina', extra: 'AR' },
    { id: '+374', value: '+374 - Armenia', extra: 'AM' },
    { id: '+297', value: '+297 - Aruba', extra: 'AW' },
    { id: '+61', value: '+61 - Cocos (Keeling) Islands', extra: 'CC' },
    { id: '+43', value: '+43 - Austria', extra: 'AT' },
    {
      id: '+994',
      value:
        '+994 - Azerbaijan or Azerbaidjan (Former Azerbaijan Soviet Socialist Republic)',
      extra: 'AZ',
    },
    {
      id: '+880',
      value: '+880 - Bangladesh (Former East Pakistan)',
      extra: 'BD',
    },
    { id: '+1-246', value: '+1-246 - Barbados', extra: 'BB' },
    {
      id: '+375',
      value:
        '+375 - Belarus (Former Belorussian [Byelorussian] Soviet Socialist Republic)',
      extra: 'BY',
    },
    { id: '+32', value: '+32 - Belgium', extra: 'BE' },
    {
      id: '+501',
      value: '+501 - Belize (Former British Honduras)',
      extra: 'BZ',
    },
    { id: '+229', value: '+229 - Benin (Former Dahomey)', extra: 'BJ' },
    { id: '+1-441', value: '+1-441 - Bermuda', extra: 'BM' },
    { id: '+591', value: '+591 - Bolivia', extra: 'BO' },
    { id: '+387', value: '+387 - Bosnia and Herzegovina', extra: 'BA' },
    { id: '+267', value: '+267 - Botswana (Former Bechuanaland)', extra: 'BW' },
    { id: '+55', value: '+55 - Brazil', extra: 'BR' },
    {
      id: '+673',
      value: '+673 - Brunei (Negara Brunei Darussalam)',
      extra: 'BN',
    },
    { id: '+359', value: '+359 - Bulgaria', extra: 'BG' },
    {
      id: '+226',
      value: '+226 - Burkina Faso (Former Upper Volta)',
      extra: 'BF',
    },
    { id: '+257', value: '+257 - Burundi (Former Urundi)', extra: 'BI' },
    {
      id: '+237',
      value: '+237 - Cameroon (Former French Cameroon)',
      extra: 'CM',
    },
    { id: '+1', value: '+1 - United States', extra: 'US' },
    { id: '+238', value: '+238 - Cape Verde', extra: 'CV' },
    { id: '+1-345', value: '+1-345 - Cayman Islands', extra: 'KY' },
    { id: '+236', value: '+236 - Central African Republic', extra: 'CF' },
    { id: '+235', value: '+235 - Chad', extra: 'TD' },
    { id: '+56', value: '+56 - Chile', extra: 'CL' },
    { id: '+86', value: '+86 - China', extra: 'CN' },
    { id: '+53', value: '+53 - Cuba', extra: 'CU' },
    { id: '+57', value: '+57 - Colombia', extra: 'CO' },
    {
      id: '+682',
      value: '+682 - Cook Islands (Former Harvey Islands)',
      extra: 'CK',
    },
    { id: '+506', value: '+506 - Costa Rica', extra: 'CR' },
    {
      id: '+225',
      value: "+225 - Cote D'Ivoire (Former Ivory Coast)",
      extra: 'CI',
    },
    { id: '+385', value: '+385 - Croatia (Hrvatska)', extra: 'HR' },
    { id: '+357', value: '+357 - Cyprus', extra: 'CY' },
    { id: '+420', value: '+420 - Czech Republic', extra: 'CZ' },
    { id: '+45', value: '+45 - Denmark', extra: 'DK' },
    { id: '+1-767', value: '+1-767 - Dominica', extra: 'DM' },
    {
      id: '+1-809 and +1-829? ',
      value: '+1-809 and +1-829?  - Dominican Republic',
      extra: 'DO',
    },
    {
      id: '+670',
      value: '+670 - East Timor (Former Portuguese Timor)',
      extra: 'TP',
    },
    { id: '+593 ', value: '+593  - Ecuador', extra: 'EC' },
    {
      id: '+20',
      value: '+20 - Egypt (Former United Arab Republic - with Syria)',
      extra: 'EG',
    },
    { id: '+503', value: '+503 - El Salvador', extra: 'SV' },
    {
      id: '+240',
      value: '+240 - Equatorial Guinea (Former Spanish Guinea)',
      extra: 'GQ',
    },
    {
      id: '+291',
      value: '+291 - Eritrea (Former Eritrea Autonomous Region in Ethiopia)',
      extra: 'ER',
    },
    {
      id: '+372',
      value: '+372 - Estonia (Former Estonian Soviet Socialist Republic)',
      extra: 'EE',
    },
    {
      id: '+500',
      value: '+500 - Falkland Islands (Islas Malvinas)',
      extra: 'FK',
    },
    { id: '+298', value: '+298 - Faroe Islands', extra: 'FO' },
    { id: '+679', value: '+679 - Fiji', extra: 'FJ' },
    { id: '+358', value: '+358 - Finland', extra: 'FI' },
    { id: '+33', value: '+33 - France', extra: 'FR' },
    { id: '+594', value: '+594 - French Guiana or French Guyana', extra: 'GF' },
    {
      id: '+689',
      value: '+689 - French Polynesia (Former French Colony of Oceania)',
      extra: 'PF',
    },
    { id: '+241', value: '+241 - Gabon (Gabonese Republic)', extra: 'GA' },
    {
      id: '+995',
      value: '+995 - Georgia (Former Georgian Soviet Socialist Republic)',
      extra: 'GE',
    },
    { id: '+49', value: '+49 - Germany', extra: 'DE' },
    { id: '+233', value: '+233 - Ghana (Former Gold Coast)', extra: 'GH' },
    { id: '+350', value: '+350 - Gibraltar', extra: 'GI' },
    { id: '+30', value: '+30 - Greece', extra: 'GR' },
    { id: '+299', value: '+299 - Greenland', extra: 'GL' },
    { id: '+1-473', value: '+1-473 - Grenada', extra: 'GD' },
    { id: '+590', value: '+590 - Guadeloupe', extra: 'GP' },
    { id: '+1-671', value: '+1-671 - Guam', extra: 'GU' },
    { id: '+502', value: '+502 - Guatemala', extra: 'GT' },
    { id: '+224', value: '+224 - Guinea (Former French Guinea)', extra: 'GN' },
    {
      id: '+245',
      value: '+245 - Guinea-Bissau (Former Portuguese Guinea)',
      extra: 'GW',
    },
    { id: '+592', value: '+592 - Guyana (Former British Guiana)', extra: 'GY' },
    { id: '+509', value: '+509 - Haiti', extra: 'HT' },
    { id: '+504', value: '+504 - Honduras', extra: 'HN' },
    { id: '+852', value: '+852 - Hong Kong', extra: 'HK' },
    { id: '+36', value: '+36 - Hungary', extra: 'HU' },
    { id: '+354', value: '+354 - Iceland', extra: 'IS' },
    { id: '+91', value: '+91 - India', extra: 'IN' },
    {
      id: '+62',
      value:
        '+62 - Indonesia (Former Netherlands East Indies; Dutch East Indies)',
      extra: 'ID',
    },
    { id: '+964', value: '+964 - Iraq', extra: 'IQ' },
    { id: '+353', value: '+353 - Ireland', extra: 'IE' },
    { id: '+972', value: '+972 - Israel', extra: 'IL' },
    { id: '+39', value: '+39 - Italy', extra: 'IT' },
    { id: '+1-876', value: '+1-876 - Jamaica', extra: 'JM' },
    { id: '+81', value: '+81 - Japan', extra: 'JP' },
    { id: '+962', value: '+962 - Jordan (Former Transjordan)', extra: 'JO' },
    { id: '+7', value: '+7 - Russian Federation', extra: 'RU' },
    {
      id: '+254',
      value: '+254 - Kenya (Former British East Africa)',
      extra: 'KE',
    },
    {
      id: '+686',
      value:
        '+686 - Kiribati (Pronounced keer-ree-bahss) (Former Gilbert Islands)',
      extra: 'KI',
    },
    { id: '+965', value: '+965 - Kuwait', extra: 'KW' },
    {
      id: '+996',
      value:
        '+996 - Kyrgyzstan (Kyrgyz Republic) (Former Kirghiz Soviet Socialist Republic)',
      extra: 'KG',
    },
    {
      id: '+856',
      value: "+856 - Lao People's Democratic Republic (Laos)",
      extra: 'LA',
    },
    {
      id: '+371',
      value: '+371 - Latvia (Former Latvian Soviet Socialist Republic)',
      extra: 'LV',
    },
    { id: '+961', value: '+961 - Lebanon', extra: 'LB' },
    { id: '+266', value: '+266 - Lesotho (Former Basutoland)', extra: 'LS' },
    { id: '+231', value: '+231 - Liberia', extra: 'LR' },
    { id: '+218', value: '+218 - Libya (Libyan Arab Jamahiriya)', extra: 'LY' },
    { id: '+423', value: '+423 - Liechtenstein', extra: 'LI' },
    {
      id: '+370',
      value: '+370 - Lithuania (Former Lithuanian Soviet Socialist Republic)',
      extra: 'LT',
    },
    { id: '+352', value: '+352 - Luxembourg', extra: 'LU' },
    { id: '+853', value: '+853 - Macau', extra: 'MO' },
    {
      id: '+261',
      value: '+261 - Madagascar (Former Malagasy Republic)',
      extra: 'MG',
    },
    { id: '+60', value: '+60 - Malaysia', extra: 'MY' },
    { id: '+960', value: '+960 - Maldives', extra: 'MV' },
    {
      id: '+223',
      value: '+223 - Mali (Former French Sudan and Sudanese Republic)',
      extra: 'ML',
    },
    { id: '+356', value: '+356 - Malta', extra: 'MT' },
    {
      id: '+692',
      value:
        '+692 - Marshall Islands (Former Marshall Islands District - Trust Territory of the Pacific Islands)',
      extra: 'MH',
    },
    { id: '+596', value: '+596 - Martinique (French)', extra: 'MQ' },
    { id: '+222', value: '+222 - Mauritania', extra: 'MR' },
    { id: '+230', value: '+230 - Mauritius', extra: 'MU' },
    {
      id: '+269',
      value: '+269 - Mayotte (Territorial Collectivity of Mayotte)',
      extra: 'YT',
    },
    { id: '+52', value: '+52 - Mexico', extra: 'MX' },
    {
      id: '+976',
      value: '+976 - Mongolia (Former Outer Mongolia)',
      extra: 'MN',
    },
    { id: '+1-664', value: '+1-664 - Montserrat', extra: 'MS' },
    { id: '+212', value: '+212 - Morocco', extra: 'MA' },
    {
      id: '+258',
      value: '+258 - Mozambique (Former Portuguese East Africa)',
      extra: 'MZ',
    },
    { id: '+674', value: '+674 - Nauru (Former Pleasant Island)', extra: 'NR' },
    { id: '+977', value: '+977 - Nepal', extra: 'NP' },
    { id: '+31', value: '+31 - Netherlands', extra: 'NL' },
    {
      id: '+599',
      value: '+599 - Netherlands Antilles (Former Curacao and Dependencies)',
      extra: 'AN',
    },
    { id: '+687', value: '+687 - New Caledonia', extra: 'NC' },
    { id: '+64', value: '+64 - New Zealand (Aotearoa)', extra: 'NZ' },
    { id: '+505', value: '+505 - Nicaragua', extra: 'NI' },
    { id: '+227', value: '+227 - Niger', extra: 'NE' },
    { id: '+234', value: '+234 - Nigeria', extra: 'NG' },
    { id: '+683', value: '+683 - Niue (Former Savage Island)', extra: 'NU' },
    {
      id: '+1-670',
      value:
        '+1-670 - Northern Mariana Islands (Former Mariana Islands District - Trust Territory of the Pacific Islands)',
      extra: 'MP',
    },
    { id: '+47', value: '+47 - Norway', extra: 'NO' },
    { id: '+92', value: '+92 - Pakistan (Former West Pakistan)', extra: 'PK' },
    {
      id: '+680',
      value:
        '+680 - Palau (Former Palau District - Trust Terriroty of the Pacific Islands)',
      extra: 'PW',
    },
    { id: '+970', value: '+970 - Palestinian State (Proposed)', extra: 'PS' },
    { id: '+507', value: '+507 - Panama', extra: 'PA' },
    {
      id: '+675',
      value:
        '+675 - Papua New Guinea (Former Territory of Papua and New Guinea)',
      extra: 'PG',
    },
    { id: '+595', value: '+595 - Paraguay', extra: 'PY' },
    { id: '+51', value: '+51 - Peru', extra: 'PE' },
    { id: '+63', value: '+63 - Philippines', extra: 'PH' },
    { id: '+48', value: '+48 - Poland', extra: 'PL' },
    { id: '+351', value: '+351 - Portugal', extra: 'PT' },
    {
      id: '+1-787 or +1-939',
      value: '+1-787 or +1-939 - Puerto Rico',
      extra: 'PR',
    },
    {
      id: '+262',
      value: '+262 - Reunion (French) (Former Bourbon Island)',
      extra: 'RE',
    },
    { id: '+40', value: '+40 - Romania', extra: 'RO' },
    {
      id: '+250',
      value: '+250 - Rwanda (Rwandese Republic) (Former Ruanda)',
      extra: 'RW',
    },
    { id: '+290', value: '+290 - Saint Helena', extra: 'SH' },
    {
      id: '+1-869',
      value:
        '+1-869 - Saint Kitts and Nevis (Former Federation of Saint Christopher and Nevis)',
      extra: 'KN',
    },
    { id: '+1-758', value: '+1-758 - Saint Lucia', extra: 'LC' },
    { id: '+508', value: '+508 - Saint Pierre and Miquelon', extra: 'PM' },
    {
      id: '+1-784',
      value: '+1-784 - Saint Vincent and the Grenadines',
      extra: 'VC',
    },
    { id: '+685', value: '+685 - Samoa (Former Western Samoa)', extra: 'WS' },
    { id: '+378', value: '+378 - San Marino', extra: 'SM' },
    { id: '+239', value: '+239 - Sao Tome and Principe', extra: 'ST' },
    { id: '+966', value: '+966 - Saudi Arabia', extra: 'SA' },
    { id: '+221', value: '+221 - Senegal', extra: 'SN' },
    { id: '+248', value: '+248 - Seychelles', extra: 'SC' },
    { id: '+232', value: '+232 - Sierra Leone', extra: 'SL' },
    { id: '+65', value: '+65 - Singapore', extra: 'SG' },
    { id: '+421', value: '+421 - Slovakia', extra: 'SK' },
    { id: '+386', value: '+386 - Slovenia', extra: 'SI' },
    {
      id: '+677',
      value: '+677 - Solomon Islands (Former British Solomon Islands)',
      extra: 'SB',
    },
    {
      id: '+27',
      value: '+27 - South Africa (Former Union of South Africa)',
      extra: 'ZA',
    },
    { id: '+34', value: '+34 - Spain', extra: 'ES' },
    {
      id: '+249',
      value: '+249 - Sudan (Former Anglo-Egyptian Sudan)',
      extra: 'SD',
    },
    { id: '+46', value: '+46 - Sweden', extra: 'SE' },
    { id: '+41', value: '+41 - Switzerland', extra: 'CH' },
    {
      id: '+963',
      value:
        '+963 - Syria (Syrian Arab Republic) (Former United Arab Republic - with Egypt)',
      extra: 'SY',
    },
    { id: '+886', value: '+886 - Taiwan (Former Formosa)', extra: 'TW' },
    {
      id: '+992',
      value: '+992 - Tajikistan (Former Tajik Soviet Socialist Republic)',
      extra: 'TJ',
    },
    { id: '+66', value: '+66 - Thailand (Former Siam)', extra: 'TH' },
    { id: '+690', value: '+690 - Tokelau', extra: 'TK' },
    { id: '+1-868', value: '+1-868 - Trinidad and Tobago', extra: 'TT' },
    { id: '+216', value: '+216 - Tunisia', extra: 'TN' },
    { id: '+90', value: '+90 - Turkey', extra: 'TR' },
    {
      id: '+993',
      value: '+993 - Turkmenistan (Former Turkmen Soviet Socialist Republic)',
      extra: 'TM',
    },
    { id: '+1-649', value: '+1-649 - Turks and Caicos Islands', extra: 'TC' },
    { id: '+688', value: '+688 - Tuvalu (Former Ellice Islands)', extra: 'TV' },
    {
      id: '+44',
      value: '+44 - United Kingdom (Great Britain / UK)',
      extra: 'GB',
    },
    {
      id: '+998',
      value: '+998 - Uzbekistan (Former UZbek Soviet Socialist Republic)',
      extra: 'UZ',
    },
    { id: '+678', value: '+678 - Vanuatu (Former New Hebrides)', extra: 'VU' },
    { id: '+418', value: '+418 - Vatican City State (Holy See)', extra: 'VA' },
    { id: '+58', value: '+58 - Venezuela', extra: 'VE' },
    { id: '+84', value: '+84 - Vietnam', extra: 'VN' },
    { id: '+681', value: '+681 - Wallis and Futuna Islands', extra: 'WF' },
    { id: '+967', value: '+967 - Yemen', extra: 'YE' },
  ];
  public static readonly Allergies: DropDownItem[] = [
    { id: 'Allium', value: 'Allium' },
    { id: 'Crustacean', value: 'Crustacean' },
    { id: 'Eggs', value: 'Eggs' },
    { id: 'Fish', value: 'Fish' },
    { id: 'Garlic', value: 'Garlic' },
    { id: 'Hazelnut', value: 'Hazelnut' },
    { id: 'Allergy', value: 'Allergy' },
    { id: 'Milk', value: 'Milk' },
    { id: 'Mushrooms', value: 'Mushrooms' },
    { id: 'Nuts', value: 'Nuts' },
    { id: 'Shellfish', value: 'Shellfish' },
    { id: 'Salt', value: 'Salt' },
    { id: 'Pregnant', value: 'Pregnant' },
    { id: 'Pork', value: 'Pork' },
    { id: 'Nightshade', value: 'Nightshade' },
    { id: 'Soy', value: 'Soy' },
    { id: 'Wheat', value: 'Wheat' },
    { id: 'Seafood', value: 'Seafood' },
    { id: 'Lactose Intolerant', value: 'Lactose Intolerant' },
    { id: 'Lupin', value: 'Lupin' },
    { id: 'Walnuts', value: 'Walnuts' },
    { id: 'Tree nuts', value: 'Tree nuts' },
    { id: 'Red meat', value: 'Red meat' },
    { id: 'Sesame', value: 'Sesame' },
    { id: 'Mustard', value: 'Mustard' },
    { id: 'Poultry', value: 'Poultry' },
    { id: 'Tomatoes', value: 'Tomatoes' },
    { id: 'Sulfites', value: 'Sulfites' },
    { id: 'Shrimp', value: 'Shrimp' },
    { id: 'Celery', value: 'Celery' },
    { id: 'Peanuts', value: 'Peanuts' },
    { id: 'Allium', value: 'Allium' },
    { id: 'Crustacean', value: 'Crustacean' },
    { id: 'Eggs', value: 'Eggs' },
    { id: 'Fish', value: 'Fish' },
    { id: 'Garlic', value: 'Garlic' },
    { id: 'Hazelnut', value: 'Hazelnut' },
    { id: 'Allergy', value: 'Allergy' },
    { id: 'Milk', value: 'Milk' },
    { id: 'Mushrooms', value: 'Mushrooms' },
    { id: 'Nuts', value: 'Nuts' },
    { id: 'Shellfish', value: 'Shellfish' },
    { id: 'Salt', value: 'Salt' },
    { id: 'Pregnant', value: 'Pregnant' },
    { id: 'Pork', value: 'Pork' },
    { id: 'Nightshade', value: 'Nightshade' },
    { id: 'Soy', value: 'Soy' },
    { id: 'Wheat', value: 'Wheat' },
    { id: 'Seafood', value: 'Seafood' },
    { id: 'Lactose Intolerant', value: 'Lactose Intolerant' },
    { id: 'Lupin', value: 'Lupin' },
    { id: 'Walnuts', value: 'Walnuts' },
    { id: 'Tree nuts', value: 'Tree nuts' },
    { id: 'Red meat', value: 'Red meat' },
    { id: 'Sesame', value: 'Sesame' },
    { id: 'Mustard', value: 'Mustard' },
    { id: 'Poultry', value: 'Poultry' },
    { id: 'Tomatoes', value: 'Tomatoes' },
    { id: 'Sulfites', value: 'Sulfites' },
    { id: 'Shrimp', value: 'Shrimp' },
    { id: 'Celery', value: 'Celery' },
    { id: 'Peanuts', value: 'Peanuts' },
  ];

  public static readonly Dietary: DropDownItem[] = [
    { id: 'Dairy-free', value: 'Dairy-free' },
    { id: 'Vegan', value: 'Vegan' },
    { id: 'Vegetarian', value: 'Vegetarian' },
    { id: 'Gluten-free', value: 'Gluten-free' },
    { id: 'Kosher', value: 'Kosher' },
    { id: 'Halal', value: 'Halal' },
    { id: 'Diabetic', value: 'Diabetic' },
    { id: 'Paleo', value: 'Paleo' },
    { id: 'Alcohol-free', value: 'Alcohol-free' },
    { id: 'Pescatarian', value: 'Pescatarian' },
  ];
}
