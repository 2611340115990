<ul class="pagination">
  <li class="page-item">
    <a class="page-link" href="#" tabindex="-1"><<</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#" tabindex="-1"><</a>
  </li>
  <li class="page-item"><a class="page-link" href="#">1</a></li>
  <li class="page-item active">
    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
  </li>
  <li class="page-item"><a class="page-link" href="#">3</a></li>
  <li class="page-item">
    <a class="page-link" href="#">> </a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#">>> </a>
  </li>
</ul>
