<div class="logo no-padding">
  <span class="simple-text no-padding">
    <div class="row">
      <div class="col-sm">
        <img class="img-fluid" src="/assets/img/thefork.jpg"/>
      </div>
    </div>
  </span>
</div>
<div class="sidebar-wrapper">
  <!--  <div class="user">-->
  <!--    <div class="photo">-->
  <!--      <img src="{{getProfileImage()}}">-->
  <!--    </div>-->
  <!--    <div class="user-info">-->
  <!--      <a (click)="collapse.toggle()"-->
  <!--         [attr.aria-expanded]="!isCollapsed">-->
  <!--        <span> {{getProfileName()}} <b class="caret"></b></span>-->
  <!--      </a>-->
  <!--      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" style="height: auto;">-->
  <!--        <ul class="nav">-->
  <!--          <li *ngFor="let route of upperMenuRoutes" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"-->
  <!--              class="nav-item">-->
  <!--            <a [routerLink]="route.path" class="nav-link">-->
  <!--              <span class="sidebar-mini">-->
  <!--                <i class="{{route.icontype}}"></i>-->
  <!--              </span>-->
  <!--              <span class="sidebar-normal">{{route.title}}</span>-->
  <!--            </a>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <ul class="nav">
    <li *ngFor="let route of currentRoutes" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
        class="nav-item">
      <ng-container *ngIf="route.children">
        <a (click)="route.isCollapse = !route.isCollapse" class="nav-link">
          <i class="{{route.icontype}}"></i>
          <p> {{route.title}}
            <b class="caret"></b>
          </p>
        </a>
        <div [ngbCollapse]="route.isCollapse">
          <ul class="nav">
            <li class="nav-item" *ngFor="let child of route.children">
              <a class="nav-link" [routerLink]="child.path">
                <span class="sidebar-mini">{{child.ab}}</span>
                <span class="sidebar-normal"> {{child.title}} </span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="!route.children">
        <a *ngIf="route.isOpenDrawer" class="nav-link cursor" (click)="toggleDrawer()">
          <i *ngIf="route.badge" ngClass="{{route.icontype}} fa-stack" [attr.data-count]="route.badge"></i>
          <i *ngIf="!route.badge" ngClass="{{route.icontype}}"></i>
          <p>{{ route.title }} <span *ngIf="route.badge" class="badge badge-azure">{{route.badge}}</span></p>
        </a>
        <a *ngIf="!route.isOpenDrawer" class="nav-link" [routerLink]="route.path">
          <i *ngIf="route.badge" ngClass="{{route.icontype}} fa-stack" [attr.data-count]="route.badge"></i>
          <i *ngIf="!route.badge" ngClass="{{route.icontype}}"></i>
          <p>{{ route.title }} <span *ngIf="route.badge" class="badge badge-azure">{{route.badge}}</span></p>
        </a>
      </ng-container>
    </li>
  </ul>
</div>
