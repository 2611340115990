import {Pipe, PipeTransform} from "@angular/core";
import {ReservationRequestStatusType} from "../enums/inventory-reason-type";

@Pipe({
  name: 'reservationRequestStatusHtml'
})
export class ReservationRequestStatusHtmlPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value != undefined) {
      switch (value as ReservationRequestStatusType) {
        case ReservationRequestStatusType.Pending:
          return '<span class="badge badge-azure">Pending</span>';
        case ReservationRequestStatusType.Reject:
          return '<span class="badge badge-danger">Rejected</span>';
        case ReservationRequestStatusType.Accept:
          return '<span class="badge badge-success">Accepted</span>';
      }
    }
    return '';
  }
}
