import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ViewImageDialogComponent} from '../../dialog/view-image-dialog/components/view-image-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  private _image = '';

  get image(): string {
    return this._image;
  }

  @Input() set image(value: string) {
    if (value == null) {
      return;
    }
    if (value.startsWith('data:image/png')) {
      this.host = '';
    }
    if (value.startsWith('http')) {
      this.host = '';
    }
    this._image = value;
  }

  @Input() popupImage?: string;
  @Input() height = 64;
  @Input() showDialog = true;
  host: string;

  constructor(private modal: MatDialog) {
    this.host = environment.baseUrl;
  }

  ngOnInit(): void {
  }


  showImage(): void {
    if (!this.showDialog) {
      return;
    }
    const modal = this.modal.open(ViewImageDialogComponent, {
      // centered: true,
      // backdrop: 'static',
      hasBackdrop: true,
    });
    const instance = modal.componentInstance as ViewImageDialogComponent;
    if (this.popupImage) {
      instance.image = this.popupImage;
    } else {
      instance.image = this.image ?? '';
    }
  }
}
