<span mat-dialog-title class="font-weight-bold">{{getType()}}</span>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm">
      <p [innerHtml]="content"></p>
    </div>
  </div>
</div>
<div mat-dialog-actions class="float-right">
  <button class="btn btn-primary btn-round btn-md font-weight-bold" (click)="OkClick()">{{ labelOk }}</button>
  &nbsp;
  <a class="text-danger cursor font-weight-bold" (click)="close()">{{ labelNo }}</a>
</div>
