import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpan } from '../models/time-span';

@Pipe({
  name: 'appTime',
})
export class AppTimePipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): string {
    if (value) {
      const t = TimeSpan.fromString(value);
      return t.toString12Hours;
    }
    return '';
  }
}
