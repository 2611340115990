import {Injectable} from "@angular/core";
import {EndpointFactoryService} from "./endpoint-factory.service";
import * as signalR from "@microsoft/signalr";
import {Observable, Subject} from "rxjs";
import {ILogger, LogLevel} from "@microsoft/signalr";

class CustomSignalRLogger implements ILogger {
  log(logLevel: LogLevel, message: string): void {
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends EndpointFactoryService {

  timerId: any = null;
  isConnected = false;
  isConnecting = false;
  private isListenersSet = false;
  hubConnection!: signalR.HubConnection;
  private newReservationRequest = new Subject<number>();
  private newReservation = new Subject<number>();
  private readonly url = this.configurations.baseUrl + '/notification';
  private option: signalR.IHttpConnectionOptions = {
    accessTokenFactory: () => this.accessToken,
  };

  getNewReservationRequestEvent(): Observable<number> {
    return this.newReservationRequest.asObservable();
  }

  getNewReservationEvent(): Observable<number> {
    return this.newReservation.asObservable();
  }

  init() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.url, this.option)
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
      .configureLogging(new CustomSignalRLogger())
      .build();
    this.addListeners();
  }

  connect() {
    if (this.isConnected) {
      return;
    }
    this.hubConnection
      .start()
      .then(() => {
        console.log('Connected');
        this.isConnected = true;
      })
      .catch(err => {
        console.log('Disconnected');
        this.isConnected = false;
      });
  }

  disconnect() {
    this.hubConnection.stop().then(() => this.isConnected = false);
  }


  addListeners() {
    if (this.isListenersSet) {
      return;
    }
    this.isListenersSet = true;
    this.hubConnection.onclose(() => {
      this.isConnected = false;
    });
    this.hubConnection.on('onNewReservation', (id) => {
      this.newReservation.next(id);
    });
    this.hubConnection.on('onNewRequest', (id) => {
      this.newReservationRequest.next(id);
    });
  }
}
