import {Component, OnInit} from '@angular/core';
import {AlertDialog, DialogType} from '../../services/alert.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  labelOk?: string;
  labelNo?: string;
  content?: string;
  type: DialogType = DialogType.close;
  OkClick?: any;
  NoClick?: () => void;
  constructor() {
  }

  initDialog(data: AlertDialog) {
    this.labelOk = data.okLabel;
    this.labelNo = data.cancelLabel;
    this.type = data.type;
    this.content = data.message;
    this.OkClick = data.okCallback;
    this.NoClick = data.cancelCallback;
  }

  ngOnInit(): void {
  }

  getType(): string {
    switch (this.type) {
      case DialogType.alert:
        return 'Alert';
      case DialogType.confirm:
        return 'Confirm';
      case DialogType.prompt:
        return 'Prompt';
      default:
        return '';
    }
  }

  close(): void {
    if (this.NoClick){
      this.NoClick();
    }
  }
}
