<mat-form-field [class.no-margins]="noMargin" [class.full-width-form]="isFullWidth">
  <mat-label>Search</mat-label>
  <input [formControl]="searchControl"
         matInput
         #searchInput type="search"
         (keydown.escape)="searchInput.value=''; searchControl.setValue(''); onSearchChange()" (keydown.enter)="onSearchChange()"
         autocomplete="off"
         placeholder="{{placeholder}}">
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
