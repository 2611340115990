import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-view-image-dialog',
  templateUrl: './view-image-dialog.component.html',
  styleUrls: ['./view-image-dialog.component.scss']
})
export class ViewImageDialogComponent implements OnInit {

  image: string = '';
  host: string = '';
  constructor(private dialog: MatDialogRef<ViewImageDialogComponent>) {
  }

  ngOnInit(): void {
    this.host = environment.baseUrl;
  }

  close(): void {
    this.dialog.close();
  }

}
