<nav #navbar [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}"
     class="navbar navbar-expand-lg navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
<!--      <div class="navbar-minimize">-->
<!--        <button (click)="minimizeSidebar()" class="btn btn-icon btn-round" id="minimizeSidebar">-->
<!--          <i class="fa fas fa-chevron-right text-center visible-on-sidebar-mini"></i>-->
<!--          <i class="fa fas fa-chevron-left text-center visible-on-sidebar-regular"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div (click)="sidebarToggle()" class="navbar-toggle">-->
<!--        <button class="navbar-toggler" type="button">-->
<!--          <i class="fa fas fa-chevron-right text-center visible-on-sidebar-mini"></i>-->
<!--          <i class="fa fas fa-chevron-left text-center visible-on-sidebar-regular"></i>-->
<!--&lt;!&ndash;          <span class="navbar-toggler-bar bar1"></span>&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="navbar-toggler-bar bar2"></span>&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="navbar-toggler-bar bar3"></span>&ndash;&gt;-->
<!--        </button>-->
<!--      </div>-->
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
    </div>
<!--    <button (click)="sidebarToggle()" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation"-->
<!--            class="navbar-toggler" data-target="#navigation" data-toggle="collapse" type="button"-->
<!--    >-->
<!--      <i class="fa fas fa-chevron-right text-center visible-on-sidebar-mini"></i>-->
<!--      <i class="fa fas fa-chevron-left text-center visible-on-sidebar-regular"></i>-->
<!--&lt;!&ndash;      <span class="navbar-toggler-bar navbar-kebab"></span>&ndash;&gt;-->
<!--&lt;!&ndash;      <span class="navbar-toggler-bar navbar-kebab"></span>&ndash;&gt;-->
<!--&lt;!&ndash;      <span class="navbar-toggler-bar navbar-kebab"></span>&ndash;&gt;-->
<!--    </button>-->
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item btn-rotate dropdown">
          <div ngbDropdown #notificationDropDown="ngbDropdown" class="d-inline-block" display="dynamic">
            <a class="nav-link dropdown-toggle cursor" (click)="toggleDropDown()" id="notificationDropDown" ngbDropdownToggle>
              <i class="fa fas fa-bell fa-stack" [attr.data-count]="getNotificationBadge"></i>
            </a>
            <div style="width: 350px" ngbDropdownMenu aria-labelledby="notificationDropDown">
              <ng-container *ngIf="rows.length > 0">
                <div class="padding-10">
                  <button (click)="markAllRead()" class="btn btn-block btn-outline-primary text-center">Mark All Read</button>
                </div>
              </ng-container>
              <ng-container *ngFor="let n of rows">
                <app-notification-view
                  [notification]="n"
                >
                </app-notification-view>
              </ng-container>
              <a (click)="viewNotifications()" ngbDropdownItem>View All</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
