import {Injectable} from '@angular/core';
import {LocalStoreManager} from './local-store-manager.service';
import {User} from '../models/user';
import {Dbkey} from './db-key';
import {Observable, Subject} from 'rxjs';
import {AlertService} from './alert.service';
import {JwtHelper} from './jwt-helper';
import {Utilities} from './utilities';
import {NavigationExtras, Router} from '@angular/router';
import {RoleNames} from '../constants/role-names';
import {LoginResponse} from '../models/login-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  roles?: string[];
  userId?: string;
  employeeId?: string;
  shopId?: string;
  isVerified?: boolean;
  private _loginStatus = new Subject<boolean>();
  private previousIsLoggedInCheck = false;

  constructor(private localStorage: LocalStoreManager,
              private router: Router,
              private alertService: AlertService) {
    this.initializeLoginStatus();
  }

  private _currentUser?: User;

  get currentUser(): User {
    if (this._currentUser == null) {
      this._currentUser = this.localStorage.getData(Dbkey.CURRENT_USER) as User;
    }
    return this._currentUser;
  }

  get accessToken(): string {
    return this.localStorage.getData(Dbkey.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date | undefined {
    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(Dbkey.TOKEN_EXPIRES_IN, true);
  }

  get refreshAccessTokenExpiryDate(): Date | undefined {
    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(Dbkey.REFRESH_TOKEN_EXPIRES_IN, true);
  }

  get isSessionExpired(): boolean {

    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
  }

  get idToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(Dbkey.ID_TOKEN);
  }

  get refreshToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(Dbkey.REFRESH_TOKEN);
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(Dbkey.REMEMBER_ME) === true;
  }

  public reevaluateLoginStatus(currentUser?: User): void {

    this._currentUser = currentUser || this.currentUser;
    const isLoggedIn = this._currentUser != null;
    this.setRole();
    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }

  saveUser(user: User) {
    this.localStorage.savePermanentData(user, Dbkey.CURRENT_USER);
  }

  logout(): void {
    this.localStorage.deleteData(Dbkey.ACCESS_TOKEN);
    this.localStorage.deleteData(Dbkey.ID_TOKEN);
    this.localStorage.deleteData(Dbkey.REFRESH_TOKEN);
    this.localStorage.deleteData(Dbkey.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(Dbkey.USER_PERMISSIONS);
    this.localStorage.deleteData(Dbkey.CURRENT_USER);
    this.roles = [];
    this.alertService.resetStickyMessage();
    this._currentUser = undefined;
    this.reevaluateLoginStatus();
    this.redirectLoggedInUser();
  }

  processLogin(result: LoginResponse): User | null {
    if (result.access_token == null) {
      throw new Error('Received token was empty');
    }

    // const expiresIn = response.expires_in;
    const jwtHelper = new JwtHelper();
    if (result.id_token != null && result.refresh_token != null) {
      const info = jwtHelper.decodeToken(result.id_token);
      const tokenExpiryDate = new Date(0);
      tokenExpiryDate.setUTCSeconds(info.exp);
      this.saveUserDetails(info as User, result.access_token, result.refresh_token, tokenExpiryDate, true);
      this.reevaluateLoginStatus(info as User);
      const user = info as User;
      this.roles = [];
      // if (Array.isArray(user.roles)) {
      //   this.roles.push(...user.roles);
      // } else {
      //   this.roles.push(user.roles);
      // }
      if (user.role != undefined) {
        this.roles.push(user.role);
      }
      return (info as User);
    }
    return null;
  }

  processRefreshToken(result: LoginResponse): User | null {
    if (result.access_token == null) {
      throw new Error('Received token was empty');
    }
    if (result.id_token != null && result.refresh_token != null) {
      const jwtHelper = new JwtHelper();
      const info = jwtHelper.decodeToken(result.id_token);
      const tokenExpiryDate = new Date(0);
      tokenExpiryDate.setUTCSeconds(info.exp);
      this.saveUserDetails(info as User, result.access_token, result.refresh_token, tokenExpiryDate, true);
      this.reevaluateLoginStatus(info as User);
      const user = info as User;
      this.roles = [];
      // if (Array.isArray(user.roles)) {
      //   this.roles.push(...user.roles);
      // } else {
      //   this.roles.push(user.roles);
      // }
      if (user.role != undefined) {
        this.roles.push(user.role);
      }
      return (info as User);
    }
    return null;
  }

  redirectLoggedInUser(): void {
    this.reevaluateLoginStatus();
    if (!this.isLoggedIn) {
      this.router.navigateByUrl('/login');
      return;
    }
    let redirect = '/';
    if (redirect === '/' && this.isRole(RoleNames.Administrator)) {
      redirect = '/dashboard/restaurants';
    }
    else if (redirect === '/' && this.isRole(RoleNames.RestaurantOwner)) {
      redirect = '/dashboard/customers';
    }
    else if (redirect === '/' && this.isRole(RoleNames.Employee)) {
      redirect = '/dashboard/customers';
    }

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge'
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  isRole(role: any): boolean {
    if (this.roles == undefined || this.roles.length === 0) {
      this.reevaluateLoginStatus();
    }
    if (this.roles !== undefined) {
      if (Array.isArray(role)) {
        return this.roles.some(p => role.indexOf(p) > -1);
      }
      return this.roles.some(p => p === role);
    }
    return false;
  }

  private initializeLoginStatus(): void {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  private setRole() {
    if (this.currentUser == null) {
      return;
    }
    this.roles = [];
    // if (Array.isArray(this.currentUser.roles)) {
    //   this.roles.push(...this.currentUser.roles);
    // } else {
    //   this.roles.push(this.currentUser.roles);
    // }
    this.roles.push(this.currentUser.role ?? '');
  }

  private saveUserDetails(user: User, accessToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean) {

    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, Dbkey.ACCESS_TOKEN);
      this.localStorage.savePermanentData(expiresIn, Dbkey.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(refreshToken, Dbkey.REFRESH_TOKEN);
      this.localStorage.savePermanentData(user, Dbkey.CURRENT_USER);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, Dbkey.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(expiresIn, Dbkey.TOKEN_EXPIRES_IN);
      this.localStorage.saveSyncedSessionData(refreshToken, Dbkey.REFRESH_TOKEN);
      this.localStorage.saveSyncedSessionData(user, Dbkey.CURRENT_USER);
    }
  }

}
