import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {
  private _control: FormControl = new FormControl(null, []);
  get control(): FormControl {
    return this._control;
  }

  @Input() set control(value: FormControl) {
    this._control = value;
  }

  @Input() isDisplayError = true;
  @Input() fieldName = 'Field';


  get showError(): string {
    if (this._control.invalid && this._control.errors != null) {
      if (this._control.errors['pattern']) {
        return this.fieldName + ' is not valid';
      }
      if (this._control.errors['match']) {
        return this.fieldName + ' does not match';
      }
      if (this._control.errors['required']) {
        return this.fieldName + ' is required';
      }
      if (this._control.errors['phone']) {
        return this.fieldName + ' has invalid format it must be (+12345678901)';
      }
      if (this._control.errors['minlength']) {
        return this.fieldName + ' must be have a length of ' + this._control.errors['minlength'].requiredLength;
      }
      if (this._control.errors['maxlength']) {
        return this.fieldName + ' must be have a length less than ' + this._control.errors['maxlength'].requiredLength;
      }
      if (this._control.errors['email']) {
        return this.fieldName + ' has invalid format it must be (test@gmail.com)';
      }
      if (this._control.errors['password']) {
        return this.fieldName + ' must contain at least 8 characters, a digit, an upper case , a lower case and a special character';
      }
      if (this._control.errors['greater']) {
        return this.fieldName + ' must be greater than ' + this._control.errors['value'];
      }

      if (this._control.errors['less']) {
        return this.fieldName + ' must be less than ' + this._control.errors['value'];
      }
    }
    return '';
  }
}
